import * as React from "react";
import "../styles/style.css";
import { MDBRow, MDBContainer, MDBCol, MDBBtn } from "mdbreact";
import { form } from "../data/site.form";
import { Footer } from "../components/Footer";
import { $ } from "../util";
import emailValidator from "email-validator";
import { navigate } from "gatsby";
import { Header } from "../components/Header";
const isContactPage = typeof window !== `undefined` ? require("../util") : null;

const FormPage = () => {
  const [busy, setBusy] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [inputState, setInputState] = React.useState({
    email: {
      value: "",
      error: false,
    },
    name: {
      value: "",
      error: false,
    },
    phone: {
      value: "",
      error: false,
    },
    checked: {
      value: false,
      error: false,
    },
  });

  const onClick = () => {
    if (busy) return;

    const email = $("email").trim();
    const name = $("name").trim();
    const phone = $("phoneNumber").trim();
    const checkbox = document.getElementById("checkbox").checked;

    if (
      !email ||
      !emailValidator.validate(email) ||
      !name ||
      !phone ||
      !checkbox
    ) {
      if (!email || !emailValidator.validate(email)) {
        inputState.email.error = true;
      }

      if (!name) {
        inputState.name.error = true;
      }

      if (!phone) {
        inputState.phone.error = true;
      }

      if (!checkbox) {
        inputState.checked.error = true;
      }
      setInputState({ ...inputState });
    } else {
      setBusy(true);

      const link = isContactPage.isContactPage()
        ? encodeURI(
            `/.netlify/functions/contact?name=${name}&email=${email}&phone=${phone}`
          )
        : encodeURI(
            `/.netlify/functions/auth?name=${name}&email=${email}&phone=${phone}`
          );

      setLoading(true);
      fetch(link)
        .then((k) => {
          setLoading(false);
          if (isContactPage.isContactPage()) {
            navigate("/contact-submitted");
          } else {
            navigate("/submitted");
          }
        })
        .catch((err) => {
          setLoading(false);
          if (isContactPage.isContactPage()) {
            navigate("/contact-error");
          } else {
            navigate("/instance-error");
          }
        });
    }
  };

  return (
    <>
      <Header />
      <MDBContainer>
        <section className="my-5">
          <h2 className="h1-responsive font-weight-bold text-center my-5">
            {form.heading}
          </h2>
          <p className="grey-text text-center w-responsive mx-auto mb-5">
            {form.subTitle}
          </p>
          <MDBRow center>
            <MDBCol sm="12" md="8" lg="6" xl="4">
              <div className="form-group">
                <label htmlFor="example1">
                  Name <span className="red-text">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  defaultValue={inputState.name.value}
                  className={
                    !inputState.name.error
                      ? "form-control form-control-lg"
                      : "form-control form-control-lg border-danger"
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="example1">
                  Email
                  <span className="red-text"> *</span>
                </label>
                <input
                  type="email"
                  id="email"
                  defaultValue={inputState.email.value}
                  className={
                    !inputState.email.error
                      ? "form-control form-control-lg"
                      : "form-control form-control-lg border-danger"
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">
                  Phone Number
                  <span className="red-text"> *</span>
                </label>
                <input
                  type="number"
                  id="phoneNumber"
                  defaultValue={inputState.phone.value}
                  className={
                    !inputState.phone.error
                      ? "form-control form-control-lg"
                      : "form-control form-control-lg border-danger"
                  }
                />
              </div>

              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className={
                    !inputState.checked.error
                      ? "custom-control-input"
                      : "custom-control-input border-danger"
                  }
                  style={{
                    borderColor: !inputState.checked.error ? "red" : "default",
                  }}
                  id="checkbox"
                />
                <label className="custom-control-label" htmlFor="checkbox">
                  <a href="https://grynn.ch/erpnext-hosting-vertrag/">
                    Agree to Terms and Conditions
                  </a>
                </label>

                <MDBRow className="mt-4" center>
                  <MDBBtn color="primary" onClick={onClick}>
                    {isLoading && (
                      <div
                        className="spinner-border text-danger spinner-border-sm mr-2"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    {isContactPage && isContactPage.isContactPage()
                      ? "Contact Us"
                      : "Create Instance"}
                  </MDBBtn>
                </MDBRow>
              </div>
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>
      <Footer />
    </>
  );
};

export default FormPage;
